@font-face {
  font-family: 'Anybody';
  src: url('./assets/fonts/Anybody.ttf') format('truetype');
}

html, body {
  font-family: -apple-system, BlinkMacSystemFont, 'Anybody', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0A0A0F;
  color: white;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden; 
  box-sizing: border-box; 
}

