.banner {
  display: flex;
  font-family: 'Anybody', sans-serif;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px;
  color: white;
  flex-shrink: 0;
  margin-top: 125px;
}

.banner h1 {
  color: white;
  font-size: 4em;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center !important;
}

.banner p {
  color: white;
  font-size: 1.2em;
  max-width: 600px;
  margin: 0 auto;
  line-height: 1.5;
  text-align: center !important;

}

.banner-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  margin-top: -80px; 
}

@media (max-width: 1700px) {
  .banner {
    margin-top: 120px;
    padding: 20px;
  }
  
  .banner h1 {
    font-size: 3em;
    margin-top: 50px;
  }
  
  .banner p {
    font-size: 1.1em;
  }

  .banner-image {
    margin-top: -10px;
    width: 100%;
    height: auto;
  }
}

@media (max-width: 768px) {
  /* For small screens */
  .banner {
    margin-top: 150px;
    padding: 15px;
  }
  
  .banner h1 {
    font-size: 1.8em;
    margin-bottom: 5px;
    white-space: nowrap;
  }
  
  .banner p {
    font-size: 1em;
  }

  .banner-image {
    margin-top: 20px;
    width: 100%;
    height: auto;
  }
}
