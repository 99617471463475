.content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 80vh;
  text-align: center;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.about-title {
  text-align: left;
  width: 100%;
  max-width: 700px;
}

.social-section {
  margin-top: 20px;
  text-align: left;
  width: 100%;
}

.social-links {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
}

.social-links a {
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: white;
  transition: color 0.3s ease, background-color 0.3s ease;
}

.social-icon {
  width: 30px;
  height: 27px;
}

@media (max-width: 768px) {
  h1, p, .social-section {
    text-align: center;
  }

  .social-links {
    justify-content: center;
  }
}

.social-text {
  font-size: 14px;

}