.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 12px;
  color: white;
  background-color: #0A0A0F;
  line-height: 1.2;
  height: 30px;
  z-index: 999;
  padding-top: 10px;
}
