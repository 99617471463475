.topbar {
  width: 90%;
  max-width: 400px;
  min-width: 300px;
  height: 40px;
  margin: 20px auto;
  background-color: #121417;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  border: 0.5px solid #B7D0FA;
  border-radius: 5px;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999;
}

.topbar-logo {
  height: 45px;
  width: 45px;
  margin-right: 20px;
}

.topbar-links {
  display: flex;
  gap: 20px;
}

.topbar-link {
  text-decoration: none;
  color: #fff;
  font-weight: regular;
  font-family: 'Anybody', sans-serif;
}

.topbar-link:hover {
  color: #B7D0FA;
}

@media screen and (max-width: 768px) {
  .topbar {
    width: fit-content;
    padding: 10px;
  }

  .topbar-logo {
    margin-right: 15px;
  }
}
