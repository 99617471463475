.page-title {
  position: relative;
  color: white;
  font-size: 28px;
  text-align: center;
  margin-top: 0px;
}

.cards-container {
  position: relative;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px 10px;
  margin-top: 300px;
}

.card-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 20px;
}

.card {
  background-color: white;
  width: 320px;
  height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 0
}

.card-image {
  width: 100%;
  height: 100%;
  object-fit: fill; 
}


.card-title {
  font-size: 18px;
  margin: 10px 0;
  text-align: center;
}

.card-description {
  font-size: 14px;
  text-align: center;
  margin-top: 25px;
  margin-bottom: 15px;
}

.card-button {
  background-color: blue;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
}

.card-button:hover {
  background-color: darkblue;
}

.card-button:disabled {
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
}

.card-button:disabled {
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
}

@media (max-width: 768px) {
  .cards-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 150px;
  }

  .card {
    width: 90%;
    height: auto;
  }

  .card-button {
    padding: 15px 25px;
    font-size: 16px;
    margin-bottom: 50px;
  }

  .page-title {
    font-size: 22px;
    margin-top: 20px;
  }
}
